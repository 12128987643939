.chart {
  width: 100%;
  height: 400px;
}
.chart:not(:first-of-type) {
  margin-top: 12px;
}

.stats {
  font-family: Minecraft;
}

.btn-color {
  width: 40px;
  height: 20px;
  cursor: pointer;
}
